@use "variables";

.projects {
  background: #6176a4;
  padding-top: 10vh;
  padding-bottom: 10vh;

  * {
    color: #e8efff;
  }
  .title {
    text-align: center;
  }
  .small-title {
    text-align: center;
    margin-bottom: 141px;
    position: relative;
    &::after {
      content: "";
      height: 71px;
      margin: 30px 0 20px;
      width: 1px;
      position: absolute;
      display: block;
      left: 50%;
      background: white;
    }
  }
  .smallest-title {
    font-size: 1.25rem;
    margin-bottom: 5px;
  }
  .project {
    position: relative;
    min-height: 34vh;
    padding: 65px 0;
    .title-container {
      position: relative;
      .section-title {
        font-size: 40px;
        position: relative;
        text-shadow: 0 0 3px #00000063;
        opacity: 0;
      }

      .big-letter {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 350px;
        line-height: 100%;
        margin-top: -67px;
        color: #6880b5;
      }
      .sub-title-description {
        position: relative;
        margin-top: 10px;
        line-height: 140%;
        opacity: 0;
      }
    }

    .gallery-container {
      opacity: 0;
      .text-container {
        position: relative;
        .link-bar {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          margin-top: 8px;
        }
      }
      .gallery-list {
        .gallery-item {
          position: relative;
          padding: 10px 15px;
          position: relative;
          background: rgba(0, 0, 0, 0.109);
          box-shadow: 0 1px 1px rgb(0 0 0 / 8%), 0 2px 2px rgb(0 0 0 / 12%);
          border-radius: 3px;

          &:hover {
            background: rgb(104 128 181);
          }
          &.active {
            background: #d9dfec;
            .gallery-item-title {
              color: #3e4d6e;
            }
          }
          .gallery-item-inner {
            height: 100%;
            .gallery-item-title {
              font-size: 18px;
            }
          }
        }
      }
      .gallery-display {
        animation: fadeIn 0.3s forwards ease-in-out;
        .gallery-display-inner {
          .image-container {
            display: block;
            border-radius: 3px;
            overflow: hidden;
            img {
              margin-bottom: -1px;
              display: block;
              width: 100%;
            }
          }
          .text-display {
            .text {
              text-align: center;
              font-size: 16px;
              padding: 5px 0;
            }
          }
        }
      }
    }
    &.inView {
      .section-title,
      .sub-title-description {
        animation: fadeInFromLeft 1s ease-in-out forwards;
      }
      .gallery-container {
        animation: fadeInFromBottom 1s ease-in-out forwards;
      }
    }
  }
}

.second,
.third {
  .image-container {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 2px 2px rgba(0, 0, 0, 0.12),
      0 4px 4px rgba(0, 0, 0, 0.16);
    height: fit-content;
    outline-offset: -3px;
    overflow: hidden;
    position: relative;
    border-radius: 3px;
    overflow: hidden;
    img {
      display: block;
      border-radius: 3px;
    }
  }
}

@media screen and (min-width: 800px) {
  .project {
    display: flex;
  }
  .title-container {
    width: 30%;
  }
  .gallery-container {
    width: 70%;
    padding-left: 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .gallery-list {
      width: 30%;

      .gallery-item {
        margin-bottom: 15px;
        &.active {
          &::before {
            content: "";
            border-top: 2px solid #d8deec;
            width: 100%;
            display: block;
            position: absolute;
            top: calc(50% - 1px);
            right: -100%;
          }
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
    .gallery-display {
      width: 60%;
      box-shadow: 0 1px 1px rgb(0 0 0 / 8%), 0 2px 2px rgb(0 0 0 / 12%),
        0 4px 4px rgb(0 0 0 / 16%);
      border-radius: 3px;
      height: fit-content;
      .gallery-display-inner {
        .image-container {
          img {
            width: 100%;
          }
        }
        .text-display {
        }
      }
    }
  }
  .projects {
    .project {
      &.simple {
        .image-container {
          width: 40%;
        }
        .text-container {
          width: 56%;
        }
      }

      &.third {
        .text-container {
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .projects {
    padding-bottom: 0;

    .small-title {
      margin-bottom: 115px;
    }
    .project {
      padding: 30px 0 60px;
      .title-container {
        margin-bottom: 15px;
        .section-title {
          font-size: 36px;
        }
      }
      .gallery-container {
        .gallery-list {
          margin-bottom: 21px;

          .gallery-item {
            background: rgb(62 77 110);
            margin-bottom: 2px;
          }
        }
      }
      &.first {
        .sub-title-description {
          margin-top: 2px;
        }
        .title-container {
          margin-bottom: 22px;
        }
      }
    }
  }
  .projects {
    .project {
      &.simple {
        .image-container {
          margin-bottom: 10px;
        }
        .small-title {
          margin-bottom: 15px;
        }
      }
    }
  }
  .projects {
  }
}
