@use "variables";

.contact {
  background: #b1c9ff;
}

.contact {
  .container.inView {
    .contactForm {
      animation: fadeInFromBottom 1s ease-in-out forwards;
    }
  }
}

.contactForm {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 600px;
  margin: auto;
  padding: 30px;
  background: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
  border-radius: 3px;
  opacity: 0;
  .title {
    margin-bottom: 30px;
    color: #6880b5;
    text-align: center;
  }
  .smallest-title {
    font-size: 1.17em;
    font-weight: bold;
    margin-bottom: 5px;
  }
  input,
  textarea {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    font-weight: bold;
    margin-bottom: 20px;
    min-height: 20px;
    border: 1px solid;
    border-color: rgba(118, 118, 118, 0.703);
    border-radius: 3px;
    padding: 4px 6px;
    font-size: 1em;
    &:focus-within,
    &:focus {
      outline: 2px solid #6565c9;
      border-color: transparent;
    }
  }
  button {
    font-size: 1.17em;
    width: fit-content;
    padding: 20px 30px;
    font-weight: bold;
    margin: auto;
    background: #6880b5;
    color: white;
    border-radius: 3px;
    &:hover {
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 600px) {
  .contact .container {
    padding: 60px 30px;
  }
  .contactForm {
    width: 100%;
  }
}
.contactForm {
  #message {
    min-height: 200px;
  }
}
