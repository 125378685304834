@use "variables";
.about {
  background: #f9f9fb;
  overflow: hidden;
  padding-top: 7vh;
  min-height: 100vh;
  padding-bottom: 3vh;
  position: relative;
}
.about .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.about .image-container {
  width: 514px;
  max-width: 38%;
  margin: auto;
  opacity: 0;
  animation: fadeInFromBottom 1s ease-in forwards;
}
.about .image-container img {
  filter: drop-shadow(2px 4px 4px grey);
}
.about {
  .small-title {
    margin-bottom: -8px;
  }
  .title {
    color: #6880b5;
  }
  .small-title {
    color: #1c2f58;
  }
}
@media screen and (max-width: 800px) {
  .about {
    min-height: 93vh;
    padding-top: 16vh;
  }
  .about .container {
    padding: 1vh 15px 7vh;
  }
  .about .image-container {
    width: 70%;
    max-width: 300px;

    margin-bottom: 10px;
    img {
    }
  }
}
@media screen and (max-width: 600px) {
  .about .image-container {
    max-width: 200px;
  }
}
.about .image-container img {
  width: 100%;
}
.about .text-wrap {
  width: 50%;
  padding: 0;
}

.about .text {
  font-size: 1.25rem;
  max-width: 60ch;
  margin-top: 15px;
}
@media screen and (max-width: 800px) {
  .about {
    .small-title {
      text-align: center;
    }
    .title {
      text-align: center;
      font-size: 2rem;
      margin-bottom: 20px;
    }
    .text {
      line-height: 110%;
      text-align: center;
    }
    .clicker-container {
      margin: 3rem auto;
    }
  }
}
.clicker-container {
  width: 300px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 2px 0 hsl(221deg 100% 73%);
  margin: 3rem 0;
  position: relative;
  height: 50px;
  border-radius: 2px;
  transform-style: preserve-3d;
}
@keyframes expand {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}
@keyframes break {
  0% {
    transform: rotate(0deg) translateY(0);
  }
  33% {
    transform: rotate(-15deg) translateY(25px);
  }
  60% {
    transform: rotate(12deg) translateY(61px) translateX(16px);
    opacity: 1;
  }
  80% {
    transform: rotate(-20deg) translateY(131px) translateX(-36px);
    opacity: 1;
  }
  100% {
    transform: rotate(0deg) translateY(800px);
    opacity: 0;
  }
}
.expand {
  animation: expand 0.3s forwards;
}
.red {
  outline: 2px solid rgba(255, 0, 0, 0.219);
}
.break {
  animation: break 2s forwards;
}
.clicker-mover {
  height: 50px;
  width: 50px;
  transition: transform 1s;
  transform: translateX(0);
  transform-style: preserve-3d;
}
.clicker {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  transform-style: preserve-3d;
  background-color: #aaffc0;
  border-radius: 2px;
  box-shadow: 0 0 2px 0 grey;
}

.clicker:hover {
  cursor: pointer;
}

.move {
  transform: translateX(250px);
}

.clicker:before {
  transition: transform 1s;
  content: "\279C";
  transform: scaleX(1);
}
.clicker:hover:before {
  filter: brightness(1.1);
}

.move.clicker-mover .clicker:before {
  transform: scaleX(-1);
}

.inside-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  line-height: 110%;
}

@media screen and (max-width: 800px) {
  .about {
    .container {
      display: block;
      .image-container {
        width: 100%;
      }
      .text-wrap {
        width: 100%;
      }
    }
  }
}

.downArrow {
  height: 40px;
  width: 40px;
  padding-bottom: 3px;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  text-decoration: none;
  font-size: 2rem;
  background: #7080b3;
  color: #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07);
  transition: all 0.2s ease-in-out;
}
@media screen and(min-width: 800px) {
  .downArrow {
    display: flex;
  }
}
@media screen and (hover: hover) and (pointer: fine) {
  .downArrow:hover {
    transform: translate(-50%, -56%);
  }
}
