* {
  margin: 0;
  padding: 0;
  text-align: left;
  box-sizing: border-box;
}
html {
  font-size: 16px;
  scroll-behavior: smooth;

  & * {
    scroll-margin-top: 90px;
  }
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
img {
  width: 100%;
}
button {
  border: none;
}

section {
  min-height: 93vh;
  display: flex;
}
.container {
  width: 1300px;
  max-width: 100%;
  margin: auto;
  padding: 0 30px;
}
