.gallery {
  min-height: 50vh;
  padding: 10vh 0 16vh;
  background: linear-gradient(
    60deg,
    transparent 45%,
    #f4f7ff 45%,
    #f4f7ff 55%,
    transparent 55%
  );
}

.container {
  width: 1300px;
  max-width: 100%;
  margin: auto;
  padding: 0 30px;
}
.gallery {
  .flexGallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 40px;
  }
  .galleryItem {
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
      0 4px 8px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    background-color: white;
    border: 4px solid #e9f0ff;
    opacity: 0;
  }
  & h3 {
    color: #1c2f58;
    margin-bottom: 50px;
    font-size: 5rem;
  }
}

@media screen and (max-width: 600px) {
  .gallery .title {
    font-size: 2.5rem;
  }
}

.galleryItem {
  & img {
    width: 100%;
    aspect-ratio: 2/1.5;
    object-fit: cover;

    border-radius: 5px;
  }
  & h4 {
    font-size: 20px;
    margin: 12px 0 10px;
    color: #1c2f58;
  }
  .stack {
    /* color: #6880b5; */
    margin: 0 0 20px;
  }
  .linkContainer {
    margin-bottom: 0;
    margin-top: auto;

    width: fit-content;
  }
  & a {
    margin: 0 10px 5px 0;
    font-weight: bold;
  }
}

.galleryItem.inView {
  animation: fadeInFromBottom 1s ease-in-out forwards;
}

@media screen and (min-width: 700px) {
  .galleryItem:nth-child(1) {
    animation-delay: 0.5s;
  }

  .galleryItem:nth-child(2) {
    animation-delay: 0.75s;
  }

  .galleryItem:nth-child(3) {
    animation-delay: 1s;
  }
}

@media screen and (max-width: 600px) {
  .gallery {
    background: linear-gradient(
      70deg,
      transparent 45%,
      #f4f7ff 45%,
      #f4f7ff 55%,
      transparent 55%
    );
  }
}
@keyframes fadeInFromBottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
