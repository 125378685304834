@use "variables";

header {
  background-color: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 997;
  box-shadow: 0 1px 2px 0px #80808052;

  transition: all 0.2s ease-in-out;
  .nav-inner {
    position: relative;
    overflow: hidden;
    height: 10vh;
    max-width: 1300px;
    padding: 20px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: height 0.2s ease-in-out;
  }
  .logo-container {
    width: 60px;
  }
  .links-list {
    list-style: none;
    display: flex;
  }
  .links-list li {
    margin: 0px 6px;
    font-size: 18px;
    // background: #6880b5;
    padding: 7px 10px;
    border-radius: 4px;
    position: relative;
    &::after {
      content: "";
      width: 80%;
      margin: auto;
      border-bottom: 1px solid #9393f13d;
      height: 1px;
      display: block;
      transition: all 0.2s ease-in-out;
    }
    &:hover {
      filter: brightness(1.3);
      &::after {
        width: 100%;
        border-color: #3e4d6e9c;
      }
    }
    a {
      color: #3e4d6e;
      text-decoration: none;
      font-weight: bold;
    }
  }
}

header {
  &.nav-scrolled {
  }
}
@media screen and (max-width: 800px) {
  header {
    .nav-inner {
      height: 13vh;
    }
    .logo-container {
      width: 158px;
      position: absolute;
      opacity: 0.1;
    }
    .links-list {
      margin: auto;
    }
    .links-list li {
      margin: 0px 2px;
      font-size: 14px;
      border-radius: 0px;
    }
  }
}
